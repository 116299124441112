<template>
    <b-card>
        <b-row class="mb-2">
            <b-col md="3" class="my-1">
                <b-input-group size="sm" prepend="Show" append="rows">
                    <b-form-select v-model="users.itemsPerPage" id="perPageSelect" size="sm" :options="users.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
            <b-col class="text-right">
                <b-button variant="primary" @click="userFormId = 0">
                    <font-awesome-icon icon="plus"></font-awesome-icon> Add user
                </b-button>
            </b-col>
        </b-row>
        <b-table :items="loadData" :fields="users.fields" :filter="users.filter" :current-page="users.currentPage"
                 :per-page="users.itemsPerPage" empty-text="No rows" empty-filtered-text="No matching rows found"
                 striped bordered hover small show-empty :responsive="true" :sort-by="users.sortBy" :sort-desc="users.sortDesc"
                 ref="usersTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in users.fields" :key="field.key"
                          :class="users.filters[index] && users.filters[index].value && typeof users.filters[index].value === 'string' ? 'non-empty' : ''">
                        <b-row v-if="users.filters[index]">
                            <b-col v-if="users.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="users.filters[index].value" debounce="300"></b-input>
                            </b-col>
                            <b-col v-else-if="users.filters[index].type === 'Select'">
                                <b-select v-model="users.filters[index].value">
                                    <b-select-option value="">All</b-select-option>
                                    <b-select-option value="TRUE">Enabled</b-select-option>
                                    <b-select-option value="FALSE">Disabled</b-select-option>
                                </b-select>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(enabled)="row">
                <b-badge variant="primary" class="p-1" v-if="row.item.enabled">Enabled</b-badge>
                <b-badge variant="warning" class="p-1" v-else>Disabled</b-badge>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" @click="enable(row.item)" v-b-tooltip.hover :title="row.item.enabled ? 'Disable' : 'Enable'" variant="outline-primary">
                        <font-awesome-icon :icon="row.item.enabled ? 'toggle-off' : 'toggle-on'"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" @click="userFormId = row.item.id" v-b-tooltip.hover title="Edit" variant="outline-primary">
                        <font-awesome-icon icon="edit"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Showing {{ start }} to {{ end }} of {{ users.totalRows }} row<template v-if="users.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="users.currentPage" :total-rows="users.totalRows"
                                      :per-page="users.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>

        <UserForm :id="userFormId" @reset-user-form-id="userFormId = null" @refresh-user-table="$refs.usersTable.refresh()"></UserForm>
    </b-card>
</template>

<script>
import tableStateMixin from '../../mixins/tableState';
import { userListUrl, userEnableUrl } from '@routes';
import * as constants from '@constants';
import UserForm from './Form.vue';

export default {
    components: {
        UserForm
    },
    mixins: [tableStateMixin],
    data() {
        return {
            users: {
                fields: [
                    { key: 'name', label: 'Name', sortable: true },
                    { key: 'email', label: 'Email', sortable: true },
                    { key: 'username', label: 'User', sortable: true },
                    { key: 'enabled', label: 'Status', class: 'text-center', sortable: true },
                    { key: 'actions', label: 'Actions', class: 'text-center' }
                ],
                filters: [
                    { column: 'name', type: 'STRING', value: '' },
                    { column: 'email', type: 'STRING', value: '' },
                    { column: 'username', type: 'STRING', value: '' },
                    { column: 'enabled', type: 'Select', value: 'ALL' },
                    { column: '', type: 'NA', value: null }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'b.name',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Users',
                path: []
            },
            userFormId: null,
            toastTitle: 'Users'
        };
    },
    mounted() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.users, this.tableState);
    },
    methods: {
        normalizeText(text) {
            if (text) {
                return text.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u0301]/g, '').normalize();
            }

            return '';
        },
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.users.filters.filter(filter => filter.value && typeof filter.value === 'string' && filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(userListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.users.totalRows = response.data.totalRows;
                        return response.data.users;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        enable(user) {
            this.$swal({
                title: `Are you sure you want to change the status to ${user.enabled ? 'disabled' : 'enable'}?`,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.axios.put(userEnableUrl(user.id), {
                        enable: !user.enabled
                    }).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });

                            this.$refs.usersTable.refresh();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('There was an error updating the data', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    });
                }
            });
        },
        tableContextChanged(context) {
            this.users.sortBy = context.sortBy;
            this.users.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.users);
        }
    },
    watch: {
        'users.filters': {
            handler() {
                this.users.currentPage = 1;
                this.$refs.usersTable.refresh();
                this.saveTableState(this.$route.name, this.users);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.users.totalRows === 0 ? 0 : (this.users.currentPage - 1) * this.users.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.users.currentPage - 1) * this.users.itemsPerPage;

            return (this.users.totalRows - offset) < this.users.itemsPerPage ? this.users.totalRows : offset + this.users.itemsPerPage;
        }
    }
};
</script>