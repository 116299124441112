<template>
    <b-modal :id="modalId" ref="modal" size="lg" centered no-close-on-backdrop no-close-on-esc>
        <template #modal-header>
            <h5>{{ user && user.id > 0 ? 'Edit user' :'Add user' }}</h5>
        </template>
        <b-overlay :show="isLoading">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveItem)" id="user-form" v-if="user">
                    <b-row class="mb-2">
                        <b-col cols="6" md="6" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Name" invalid-feedback="Enter the name">
                                    <b-form-input v-model="user.name" autocomplete="false" placeholder="Name"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="6" md="6" class="text-left">
                            <ValidationProvider rules="required|email" v-slot="{ errors }" name="Email">
                                <b-form-group label="Email" :invalid-feedback="errors[0]">
                                    <b-form-input v-model="user.email" autocomplete="false" placeholder="Email"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="User" invalid-feedback="Enter the user">
                                    <b-form-input v-model="user.username" autocomplete="false" placeholder="User"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col v-if="user.id">
                            <b-form-group label="Update password">
                                <b-form-checkbox v-model="updatedPassword" name="check-button" switch></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <ValidationProvider :rules="passwordRules" v-slot="{ errors }" name="Password" vid="password">
                                <b-form-group label="Password" :invalid-feedback="errors[0]">
                                    <b-form-input name="password" type="password" v-model="user.password" :state="errors[0] ? false : null"
                                                  autocomplete="off" :disabled="user.id ? !updatedPassword : false" placeholder="Password"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider :rules="passwordConfirmationRules" v-slot="{ errors }" name="Confirm password">
                                <b-form-group label="Confirm password" :invalid-feedback="errors[0]">
                                    <b-form-input  name="password_confirmation  " type="password" v-model="user.passwordConfirmation" :state="errors[0] ? false : null"
                                                   autocomplete="off" :disabled="user.id ? !updatedPassword : false" placeholder="Confirm password"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
        </b-overlay>
        <template #modal-footer>
            <div class="text-right">
                <b-button @click="cancelAction" class="mr-2" variant="default"><font-awesome-icon icon="ban"></font-awesome-icon> Cancel</b-button>
                <b-button type="submit" form="user-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { extend } from 'vee-validate';
import { userSaveUrl, userUrl } from '@routes';
import * as constants from '@constants';
import { required, confirmed, email } from 'vee-validate/dist/rules';

extend('required', {
    ...required
});

extend('email', {
    ...email
});

extend('confirmed', {
    ...confirmed,
    message: 'Password must be the same'
});

export default {
    name: 'UserForm',
    props: {
        id: Number
    },
    data() {
        return {
            modalId: 'user-form-modal',
            toastTitle: 'Users',
            user: {
                id: 0,
                name: '',
                email: '',
                username: '',
                password: '',
                passwordConfirmation: '',
                updatedPassword: false
            },
            isLoading: false,
            updatedPassword: false
        };
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(userUrl(this.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.user = response.data.user;
                    this.user.password = '';
                    this.user.passwordConfirmation = '';
                    this.user.updatedPassword = false;
                    this.updatedPassword = false;
                }

                this.$bvModal.show(this.modalId);
            }).catch(error => {
                console.error(error);

                this.$emit('reset-user-form-id');

                this.$bvToast.toast('There was an error getting the part data', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        saveItem() {
            this.isLoading = true;

            this.axios.post(userSaveUrl(), this.user).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });

                    this.$bvModal.hide(this.modalId);
                    this.$emit('reset-user-form-id');
                    this.$emit('refresh-user-table');
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                console.error(error);

                this.$bvToast.toast('There was an error saving the part', {
                    title: this.toastTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        cancelAction() {
            this.$bvModal.hide(this.modalId);
            this.$emit('reset-user-form-id');
        }
    },
    watch: {
        id() {
            if (this.id !== null) {
                if (this.id > 0) {
                    this.loadData();
                } else {
                    this.user = {
                        id: 0,
                        name: '',
                        email: '',
                        username: '',
                        password: '',
                        passwordConfirmation: '',
                        updatedPassword: false
                    };

                    this.$bvModal.show(this.modalId);
                }
            }
        },
        updatedPassword() {
            this.user.updatedPassword = this.updatedPassword;
        }
    },
    computed: {
        passwordRules() {
            return this.updatedPassword || this.user.id === 0 ? 'required' : '';
        },
        passwordConfirmationRules() {
            return this.updatedPassword || this.user.id === 0 ? 'required|confirmed:password' : '';
        }
    }
};
</script>